import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import validator from 'validator';
import InputText from '../../Components/Commons/InputText/InputText';
import { Button } from 'antd';
import { forgotPasswordRequest } from '../../Redux/Features/forgotPasswordRequestSlice';
import styles from './ForgotPasswordRequest.module.scss';

const ForgotPasswordRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state?.forgotPasswordRequest);
  const [clientError, setClientError] = useState({});
  const [formData, setFormData] = useState({
    email: '',
  });

  const setFeild = (feildName, feildValue) => {
    setFormData({ ...formData, [feildName]: feildValue });
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();
    let isErr = false;
    let errors = {};

    if (!validator.isEmail(formData?.email)) {
      isErr = true;
      errors.email = 'Email is invalid';
    }

    if (validator.isEmpty(formData?.email)) {
      isErr = true;
      errors.email = 'Email is required';
    }

    if (isErr) {
      isErr = false;
      setClientError(errors);
    } else {
      setClientError({});
      const data = {
        data: {
          ...formData,
        },
        navigate,
        onSuccess: () => {
          setFormData({
            email: '',
          });
        },
      };
      dispatch(forgotPasswordRequest(data));
    }
  };

  return (
    <div className={styles.main_forgot_passowrd_container}>
      <div className={styles.forgot_password_container}>
        <div className={styles.title_container}>
          <h2>Forgot Password</h2>
        </div>
        <div className={styles.form_container}>
          <div className={styles.input_container}>
            <InputText
              label={'Email *'}
              error={clientError?.email ? clientError?.email : false}
              value={formData?.email}
              size={'large'}
              onChange={(e) => setFeild('email', e?.target?.value)}
            />
          </div>
          <div className={styles.links_container}>
            <p>
              <Link to={`/login`}> Return to login</Link>
            </p>
          </div>
          <div className={styles.button_container}>
            <Button onClick={handleSubmit} disabled={isLoading} loading={isLoading}>
              Continue
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordRequest;
