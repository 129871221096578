import { configureStore } from '@reduxjs/toolkit';
import addLinkedinProfileSlice from './Features/addLinkedinProfileSlice';
import changePasswordSlice from './Features/changePasswordSlice';
import emailCodeVerifySlice from './Features/emailCodeVerifySlice';
import forgotPasswordRequestSlice from './Features/forgotPasswordRequestSlice';
import getLinkedinProfilesSlice from './Features/getLinkedinProfilesSlice';
import linkedinPinSlice from './Features/linkedinPinSlice';
import linkedinProfileDeleteSlice from './Features/linkedinProfileDeleteSlice';
import linkedinSessionStatusSlice from './Features/linkedinSessionStatusSlice';
import setNewPasswordSlice from './Features/setNewPasswordSlice';
import startLinkedinSessionSlice from './Features/startLinkedinSessionSlice';
import updateProfileSlice from './Features/updateProfileSlice';
import userLoginSlice from './Features/userLoginSlice';
import userRegisterSlice from './Features/userRegisterSlice';
import userSlice from './Features/userSlice';
import getLinkedinProfileSlice from './Features/getLinkedinProfileSlice';
import updateLinkedinProfileSlice from './Features/updateLinkedinProfileSlice';
import loadBusinessPagesSlice from './Features/loadBusinessPagesSlice';
import createCampaignSlice from './Features/createCampaignSlice';
import getCampaignsSlice from './Features/getCampaignsSlice';
import deleteCampaignSlice from './Features/deleteCampaignSlice';
import getCampaignSlice from './Features/getCampaignSlice';
import getProductsSlice from './Features/getProductsSlice';
import getEngagementsSlice from './Features/getEngagementsSlice';
import updateCampaignSlice from './Features/updateCampaignSlice';
import pauseCampaignSlice from './Features/pauseCampaignSlice';
import resumeCampaignSlice from './Features/resumeCampaignSlice';
import getAccountsSlice from './Features/getAccountsSlice';
import getCompaniesSlice from './Features/getCompaniesSlice';
import repayCampaignSlice from './Features/repayCampaignSlice';
import updateSubscriptionSlice from './Features/updateSubscriptionSlice';
import updateCampaignInternalSubscriptionSlice from './Features/updateCampaignInternalSubscriptionSlice';

export const store = configureStore({
  reducer: {
    user: userSlice,
    userLogin: userLoginSlice,
    userRegister: userRegisterSlice,
    emailCodeVerify: emailCodeVerifySlice,
    forgotPasswordRequest: forgotPasswordRequestSlice,
    setNewPassword: setNewPasswordSlice,
    updateProfile: updateProfileSlice,
    changePassword: changePasswordSlice,
    addLinkedinProfile: addLinkedinProfileSlice,
    startLinkedinSession: startLinkedinSessionSlice,
    linkedinSessionStatus: linkedinSessionStatusSlice,
    linkedinPin: linkedinPinSlice,
    linkedinProfileDelete: linkedinProfileDeleteSlice,
    getLinkedinProfiles: getLinkedinProfilesSlice,
    getLinkedinProfile: getLinkedinProfileSlice,
    updateLinkedinProfile: updateLinkedinProfileSlice,
    loadBusinessPages: loadBusinessPagesSlice,
    createCampaign: createCampaignSlice,
    getCampaigns: getCampaignsSlice,
    deleteCampaign: deleteCampaignSlice,
    getCampaign: getCampaignSlice,
    getProducts: getProductsSlice,
    getEngagements: getEngagementsSlice,
    updateCampaign: updateCampaignSlice,
    updateSubscription: updateSubscriptionSlice,
    pauseCampaign: pauseCampaignSlice,
    resumeCampaign: resumeCampaignSlice,
    getAccounts: getAccountsSlice,
    getCompanies: getCompaniesSlice,
    repayCampaign: repayCampaignSlice,
    updateCampaignInternalSubscription: updateCampaignInternalSubscriptionSlice,
  },
});
