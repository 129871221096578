import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import { setCookie } from '../../utils/helper';
import { getUser } from './userSlice';
import { notification } from 'antd';

const initialState = {
  isLoading: false,
  isError: null,
  errors: [],
  isSuccess: false,
  message: '',
};

export const userLogin = createAsyncThunk(
  'user/login',
  async ({ data, navigate, onSuccess }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(`${BASE_URL}/api/auth/user/login`, data);
      let token = response?.data?.token;
      dispatch(getUser(token));
      setCookie('token', token);
      response?.data?.message && notification.success({ message: 'Success', description: response?.data?.message });
      onSuccess && onSuccess();
      navigate(`/`);
      return response?.data;
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error?.response?.data?.errors[0]?.message || 'Network error',
      });
      return rejectWithValue(error?.response?.data);
    }
  }
);

const userLoginSlice = createSlice({
  name: 'User Login',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userLogin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.errors = [];
      state.isSuccess = action.payload?.success;
      state.message = action.payload?.message;
    });
    builder.addCase(userLogin.rejected, (state, action) => {
      state.isLoading = false;
      state.errors = action.payload?.errors || [];
      state.isError = true;
    });
  },
});

export default userLoginSlice?.reducer;
