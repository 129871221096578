import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../Config';

const initialState = {
  isLoading: false,
  isError: null,
  errors: [],
  isSuccess: false,
  message: '',
  profile: {},
};

export const getLinkedinProfileById = createAsyncThunk(
  'linkedin/get-profile',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/linkedin/profile/${id}`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const getLinkedinProfileSlice = createSlice({
  name: 'Get Linkedin Profile',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLinkedinProfileById.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(getLinkedinProfileById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.errors = [];
      state.isSuccess = action?.payload?.success;
      state.message = action?.payload?.message;
      state.profile = action?.payload?.profile;
    });
    builder.addCase(getLinkedinProfileById.rejected, (state, action) => {
      state.isLoading = false;
      state.errors = action.payload?.errors || [];
      state.isError = true;
    });
  },
});

export default getLinkedinProfileSlice?.reducer;
