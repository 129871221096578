import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import { notification } from 'antd';

const initialState = {
  isLoading: false,
  isError: null,
  errors: [],
  isSuccess: false,
  message: '',
};

export const userRegister = createAsyncThunk(
  'user/register',
  async ({ data, navigate, onSuccess }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(`${BASE_URL}/api/auth/user/register`, data);
      response?.data?.message && notification.success({ message: 'Success', description: response?.data?.message });
      onSuccess && onSuccess();

      let email = response?.data?.email;
      const getVerifyEmailHtmlTemplateResponse = await axios.post(`${BASE_URL}/api/auth/user/verify`, { email });
      getVerifyEmailHtmlTemplateResponse?.data?.message &&
        notification.success({ message: 'Success', description: getVerifyEmailHtmlTemplateResponse?.data?.message });

      if (getVerifyEmailHtmlTemplateResponse?.data?.success) {
        navigate(`/email-sent`);
      }
      return response?.data;
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error?.response?.data?.errors[0]?.message || 'Network error',
      });
      return rejectWithValue(error?.response?.data);
    }
  }
);

const userRegisterSlice = createSlice({
  name: 'User Register',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userRegister.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(userRegister.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.errors = [];
      state.isSuccess = action.payload?.success;
      state.message = action.payload?.message;
    });
    builder.addCase(userRegister.rejected, (state, action) => {
      state.isLoading = false;
      state.errors = action.payload?.errors || [];
      state.isError = true;
    });
  },
});

export default userRegisterSlice?.reducer;
