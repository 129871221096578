import { Button } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '../../Components/Commons/Spinner/Spinner';
import { verifyCodeForEmail } from '../../Redux/Features/emailCodeVerifySlice';
import styles from './Verification.module.scss';

const Verification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError } = useSelector((state) => state?.emailCodeVerify);
  const { code } = useParams();

  useEffect(() => {
    let data = {
      data: {
        code,
      },
      navigate,
    };
    dispatch(verifyCodeForEmail(data));
  }, [code]);

  return (
    <div className={styles.verification_container}>
      {isError ? <Button onClick={() => navigate(`/register`)}>Register Here</Button> : <Spinner size={100} />}
    </div>
  );
};

export default Verification;
