import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './Redux/store';
import { ConfigProvider } from 'antd';
import { theme } from './utils/themeConfig';
import './Styles/global.scss';
import { BASE_URL } from './Config';

if (!BASE_URL) {
  throw "BASE_URL is not set. Please configure that url first.";
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ConfigProvider theme={theme}>
      <App />
    </ConfigProvider>
  </Provider>
);

reportWebVitals();
