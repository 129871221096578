import React, { useEffect, useState } from 'react';
import AuthLayout from '../../../Layouts/AuthLayout/AuthLayout';
import { useDispatch, useSelector } from 'react-redux';
import { getLinkedinProfiles } from '../../../Redux/Features/getLinkedinProfilesSlice';
import SettingsHeader from '../../../Components/SettingsHeader/SettingsHeader';
import styles from './AllProfiles.module.scss';
import { Avatar, Button, Col, Image, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import AddLinkedinProfile from '../../../Components/Linkedin/AddLinkedinProfile/AddLinkedinProfile';

const AllProfiles = () => {
  const dispatch = useDispatch();
  const { profiles } = useSelector((state) => state?.getLinkedinProfiles);
  const [addLinkedin, setAddLinkedin] = useState(false);
  const navigate = useNavigate();

  const isValidLinkedinProfileImageUrl = (url) => {
    const regex = /^https?:\/\/media\.(licdn|linkedin)\.com\/(.*?)$/i;
    return regex.test(url);
  };

  useEffect(() => {
    dispatch(getLinkedinProfiles());
  }, []);

  return (
    <AuthLayout>
      <SettingsHeader
        content={
          <div className={styles.profile_setting_container}>
            <div className={styles.profile_details}>
              <h2>Your LinkedIn Profiles</h2>
            </div>
          </div>
        }
      />

      <AddLinkedinProfile open={addLinkedin} setOpen={setAddLinkedin} />
      <div className={styles.main_linkedin_profiles_container}>
        <Row>
          <Col xs={24} sm={24} md={12}>
            <div className={styles.linkedin_profiles_container}>
              <div className={styles.header_details}>
                <h6>LinkedIn Profiles</h6>
                <Button size="small" type="primary" onClick={() => setAddLinkedin(true)}>
                  Add new LinkedIn Account
                </Button>
              </div>

              <div className={styles.profiles_table_container}>
                <div className={styles.profiles_title}>
                  <div className={styles.name}>
                    <p>Name</p>
                  </div>
                  <div className={styles.status}>
                    <p>Status</p>
                  </div>
                </div>
                {profiles[0] ? (
                  profiles?.map((item, i) => (
                    <div
                      key={i}
                      className={styles.profile_details}
                      onClick={() => navigate(`/linkedin-profiles/profile/${item?._id}`)}
                    >
                      <div className={styles.name}>
                        <Avatar
                          src={
                            <Image
                              src={
                                isValidLinkedinProfileImageUrl(item?.linkedin_profile_pic)
                                  ? item?.linkedin_profile_pic
                                  : '/Assets/placeholder.png'
                              }
                              preview={false}
                              onError={(e) => {
                                e.target.src = '/Assets/placeholder.png';
                              }}
                            />
                          }
                          alt={'Profile image'}
                          size={'default'}
                        />
                        <p>{item?.linkedin_name ? item?.linkedin_name : 'N/A'}</p>
                      </div>
                      <div className={styles.status}>
                        <p>{item?.status?.toLowerCase() ? item?.status?.toLowerCase() : 'N/A'}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className={styles.no_profile_container}>
                    <p>Profiles not found</p>
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <div className={styles.profile_not_selected_container}>
              <p>Please select a profile to show</p>
            </div>
          </Col>
        </Row>
      </div>
    </AuthLayout>
  );
};

export default AllProfiles;
