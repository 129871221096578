import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../Config';

const initialState = {
  isLoading: false,
  isError: null,
  errors: [],
  isSuccess: false,
  message: '',
  id: '',
};

export const updateLinkedinProfile = createAsyncThunk(
  'linkedin/update-profile',
  async ({ data, onSuccess, id, notify, onError }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.put(`${BASE_URL}/api/linkedin/update-profile/${id}`, data);
      onSuccess && onSuccess();
      return response?.data;
    } catch (error) {
      onError && onError();
      return rejectWithValue(error?.response?.data);
    }
  }
);

const updateLinkedinProfileSlice = createSlice({
  name: 'Update Linkedin Profile',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateLinkedinProfile.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = false;
    });
    builder.addCase(updateLinkedinProfile.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.errors = [];
      state.isSuccess = action?.payload?.success;
      state.message = action?.payload?.message;
      state.id = action?.payload?.id;
    });
    builder.addCase(updateLinkedinProfile.rejected, (state, action) => {
      state.isLoading = false;
      state.errors = action.payload?.errors || [];
      state.isError = true;
    });
  },
});

export default updateLinkedinProfileSlice?.reducer;
