import { Button, Image } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import validator from 'validator';
import InputText from '../../Components/Commons/InputText/InputText';
import { userRegister } from '../../Redux/Features/userRegisterSlice';
import { BASE_URL } from '../../Config';
import styles from './Register.module.scss';
import UnAuthLayout from '../../Layouts/UnAuthLayout/UnAuthLayout';
import { removeCookie, setCookie } from '../../utils/helper';

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state?.userRegister);
  const [clientError, setClientError] = useState({});
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
  });

  const setFeild = (feildName, feildValue) => {
    setFormData({ ...formData, [feildName]: feildValue });
  };

  const handleSubmit = async () => {
    let isErr = false;
    let errors = {};

    if (!validator.isEmail(formData?.email)) {
      isErr = true;
      errors.email = 'Email is invalid';
    }
    if (!validator.isLength(formData?.password, { min: 6 })) {
      isErr = true;
      errors.password = 'Password should be 6 or more characters long';
    }
    if (validator.isEmpty(formData?.name)) {
      isErr = true;
      errors.name = 'Name is required';
    }
    if (validator.isEmpty(formData?.email)) {
      isErr = true;
      errors.email = 'Email is required';
    }
    if (validator.isEmpty(formData?.password)) {
      isErr = true;
      errors.password = 'Password is required';
    }

    if (isErr) {
      isErr = false;
      setClientError(errors);
    } else {
      setClientError({});
      const data = {
        data: {
          ...formData,
        },
        navigate,
        onSuccess: () => {
          setFormData({
            name: '',
            email: '',
            password: '',
          });
        },
      };
      dispatch(userRegister(data));
    }
  };

  const handleGoogle = () => {
    window.open(`${BASE_URL}/api/auth/google`, '_self');
  };

  const handleRemeberMe = (isRemember) => {
    if (isRemember) {
      setCookie('remember', isRemember);
    } else {
      removeCookie('remember');
    }
  };

  useEffect(() => {
    removeCookie('remember');
  }, []);

  return (
    <UnAuthLayout>
      <div className={styles.main_register_container}>
        <h1>Join TRONI</h1>
        <p></p>
        <div className={styles.register_form_container}>
          <div className={styles.input_container}>
            <InputText
              label={'Full name'}
              error={clientError?.name ? clientError?.name : false}
              value={formData?.name}
              size={'large'}
              onChange={(e) => setFeild('name', e?.target?.value)}
            />
            <InputText
              type={'email'}
              label={'Email address'}
              error={clientError?.email ? clientError?.email : false}
              value={formData?.email}
              size={'large'}
              onChange={(e) => setFeild('email', e?.target?.value)}
            />
            <InputText
              label={'Password'}
              size={'large'}
              type={'password'}
              error={clientError?.password ? clientError?.password : false}
              value={formData?.password}
              onChange={(e) => setFeild('password', e?.target?.value)}
            />
          </div>
          <div className={styles.saved_and_account_container}>
            <div className={styles.remember}>
              <InputText size={'small'} type={'checkbox'} onChange={(e) => handleRemeberMe(e?.target?.checked)} />
              <span>Remember me</span>
            </div>
            <div className={styles.have_and_account}>
              <span>
                Already have an account? <Link to={'/login'}>Sign In</Link>
              </span>
            </div>
          </div>
          <div className={styles.button_container}>
            <Button onClick={handleSubmit} disabled={isLoading} loading={isLoading} type="primary" size="large" block>
              Sign Up
            </Button>
          </div>
        </div>
        <div className={styles.google_login_button}>
          <Button onClick={handleGoogle} size="large" block>
            <Image preview={false} src={'/Assets/auth/google_logo.svg'} /> <span>Sign up with Google</span>
          </Button>
        </div>
      </div>
    </UnAuthLayout>
  );
};

export default Register;
