import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import { Error } from '../../Components/Commons/Message/Message';

const initialState = {
  isLoading: false,
  isError: null,
  errors: [],
  isSuccess: false,
  message: '',
  sessionId: '',
};

export const startLinkedinSession = createAsyncThunk(
  'linkedin/start-session',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(`${BASE_URL}/api/linkedin/session/start`, data);
      return response?.data;
    } catch (error) {
      Error(error?.response?.data?.errors[0]?.message || 'Network error');
      return rejectWithValue(error?.response?.data);
    }
  }
);

const startLinkedinSessionSlice = createSlice({
  name: 'Start Linkedin Session',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(startLinkedinSession.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(startLinkedinSession.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.errors = [];
      state.isSuccess = action?.payload?.success;
      state.message = action?.payload?.message;
      state.sessionId = action?.payload?.sessionId;
    });
    builder.addCase(startLinkedinSession.rejected, (state, action) => {
      state.isLoading = false;
      state.errors = action.payload?.errors || [];
      state.isError = true;
    });
  },
});

export default startLinkedinSessionSlice?.reducer;
