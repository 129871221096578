import React, { Fragment, useEffect, useState } from 'react';
import AuthLayout from '../../../Layouts/AuthLayout/AuthLayout';
import SettingsHeader from '../../../Components/SettingsHeader/SettingsHeader';
import { Avatar, Button, Col, Row, Dropdown, Image } from 'antd';
import styles from './Profile.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getLinkedinProfileById } from '../../../Redux/Features/getLinkedinProfileSlice';
import cc from 'classnames';
import { formatDateToStandard } from '../../../utils/helper';
import moment from 'moment';
import AddLinkedinProfile from '../../../Components/Linkedin/AddLinkedinProfile/AddLinkedinProfile';
import UpdateLinkedinProfile from '../../../Components/Linkedin/UpdateLinkedinProfile/UpdateLinkedinProfile';
import DeleteLinkedinProfile from '../../../Components/Linkedin/DeleteLinkedinProfile/DeleteLinkedinProfile';
import { clearErrorsState, loadBusinessPages } from '../../../Redux/Features/loadBusinessPagesSlice';

const Profile = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [addLinkedin, setAddLinkedin] = useState(false);
  const [updateLinkedin, setUpdateLinkedin] = useState(false);
  const [deleteLinkedinProfile, setDeleteLinkedinProfile] = useState(false);
  const { profiles } = useSelector((state) => state?.getLinkedinProfiles);
  const { profile, isError, errors } = useSelector((state) => state?.getLinkedinProfile);
  const { isLoading: businessPageLoading, errors: loadBusinessErrors } = useSelector(
    (state) => state?.loadBusinessPages
  );
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getLinkedinProfileById(id));
    dispatch(clearErrorsState());
  }, [id, profiles]);

  const isValidLinkedinProfileImageUrl = (url) => {
    const regex = /^https?:\/\/media\.(licdn|linkedin)\.com\/(.*?)$/i;
    return regex.test(url);
  };

  const handleActiveProfile = (profileId, baseId) => profileId === baseId;

  const handleRecentActivity = () => {
    if (!profile?._id) return [];
    const recentActicity = [...profile?.activity];
    if (!recentActicity[0]) return [];

    let sortedLogs = recentActicity.sort(function (a, b) {
      return new Date(b?.timestamp) - new Date(a?.timestamp);
    });

    return sortedLogs;
  };

  const handleStatusStyle = (status) => {
    if (status === 'CONNECTED') {
      return styles.status_success;
    } else {
      return styles.status_failure;
    }
  }

  const handleLogColor = (log) => {
    switch (log) {
      case 'log':
        return styles.success;
      case 'warning':
        return styles.warning;
      case 'error':
        return styles.error;

      default:
        return styles.success;
    }
  };

  const handleLoadBusinessPages = () => {
    dispatch(loadBusinessPages(id));
  };

  const handleAvailableBusinessPages = () => {
    if (!profile?.business_pages) return;
    const businessPages = [...profile?.business_pages];
    const displayList = [];
    for (let index = 0; index < businessPages?.length; index++) {
      const item = businessPages[index];
      displayList?.push({ label: item?.name, value: item?.value });
    }
    return displayList[0] ? displayList : [];
  };

  return (
    <AuthLayout>
      <SettingsHeader
        content={
          <div className={styles.profile_setting_container}>
            <div className={styles.profile_details}>
              <h2>Your LinkedIn Profile</h2>
            </div>
          </div>
        }
      />
      <AddLinkedinProfile open={addLinkedin} setOpen={setAddLinkedin} />
      <UpdateLinkedinProfile open={updateLinkedin} setOpen={setUpdateLinkedin} />
      <DeleteLinkedinProfile open={deleteLinkedinProfile} setOpen={setDeleteLinkedinProfile} />

      <div className={styles.main_linkedin_profiles_container}>
        <Row gutter={[20, 30]}>
          <Col xs={24} sm={24} md={24} lg={12}>
            <div className={styles.linkedin_profiles_container}>
              <div className={styles.header_details}>
                <h6>LinkedIn Profiles</h6>
                <div className={styles.button_container}>
                  <Button size="small" type="primary" onClick={() => setAddLinkedin(true)}>
                    Add new LinkedIn Account
                  </Button>
                </div>
              </div>

              <div className={styles.profiles_table_container}>
                <div className={styles.profiles_title}>
                  <div className={styles.name}>
                    <p>Name</p>
                  </div>
                  <div className={styles.status}>
                    <p>Status</p>
                  </div>
                </div>
                {profiles[0] ? (
                  profiles?.map((item, i) => (
                    <div
                      key={i}
                      className={cc(
                        styles.profile_details,
                        handleActiveProfile(item?._id, id) ? styles.active_profile_detail : ''
                      )}
                      onClick={() => navigate(`/linkedin-profiles/profile/${item?._id}`)}
                    >
                      <div className={styles.name}>
                        <Avatar
                          src={
                            <Image
                              src={
                                isValidLinkedinProfileImageUrl(item?.linkedin_profile_pic)
                                  ? item?.linkedin_profile_pic
                                  : '/Assets/placeholder.png'
                              }
                              onError={(e) => {
                                e.target.src = '/Assets/placeholder.png';
                              }}
                              preview={false}
                            />
                          }
                          alt={'Profile image'}
                          size={'default'}
                        />
                        <p>{item?.linkedin_name ? item?.linkedin_name : 'N/A'}</p>
                      </div>
                      <div className={handleStatusStyle(item?.status)}>
                        <p>{item?.status?.toLowerCase() ? item?.status?.toLowerCase() : 'N/A'}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className={styles.no_profile_container}>
                    <p>Profiles not found</p>
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            {!profile?._id || isError ? (
              <div className={styles.selected_profile_not_found}>
                <p>{errors[0]?.message || 'Profile Not Found'}</p>
              </div>
            ) : (
              <Fragment>
                <div className={styles.selected_profile_container}>
                  <div className={styles.header_details}>
                    <div className={styles.header_name}>
                      <Avatar
                        src={
                          <Image
                            src={
                              isValidLinkedinProfileImageUrl(profile?.linkedin_profile_pic)
                                ? profile?.linkedin_profile_pic
                                : '/Assets/placeholder.png'
                            }
                            onError={(e) => {
                              e.target.src = '/Assets/placeholder.png';
                            }}
                            preview={false}
                          />
                        }
                        alt={'Profile image'}
                        size={'default'}
                      />
                      <h5>{profile?.linkedin_name ? profile?.linkedin_name : 'N/A'}</h5>
                    </div>
                    <Button danger size="small" type="primary" onClick={() => setDeleteLinkedinProfile(true)}>
                      Delete
                    </Button>
                    <Button size="small" type="primary" onClick={() => setUpdateLinkedin(true)}>
                      Update LinkedIn Credentials
                    </Button>
                  </div>

                  <div className={styles.details_table_container}>
                    <table>
                      <thead>
                        <tr>
                          <th className={styles.status}>
                            <p>Status</p>
                          </th>
                          <th className={styles.date}>
                            <p>Date</p>
                          </th>
                          <th className={styles.time}>
                            <p>Time</p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {handleRecentActivity()?.map((item, i) => (
                          <tr key={i}>
                            <td className={styles.status}>
                              <p className={handleLogColor(item?.type)} key={i}>
                                {item?.message}
                              </p>
                            </td>
                            <td className={styles.date}>
                              <p>{formatDateToStandard(item?.timestamp)}</p>
                            </td>
                            <td className={styles.time}>
                              <p>{moment(item?.timestamp).format('hh:mm A')} </p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className={styles.business_pages_container}>
                  <div className={styles.header_details}>
                    <div className={styles.header_name}>
                      <h5>Business Pages</h5>
                    </div>
                    <Button size="small" type="primary" onClick={handleLoadBusinessPages} loading={businessPageLoading}>
                      Load business pages
                    </Button>
                  </div>

                  <div className={styles.details_table_container}>
                    {handleAvailableBusinessPages()[0] ? (
                      <table>
                        <thead>
                          <tr>
                            <th className={styles.status}>
                              <p>Name</p>
                            </th>
                            <th className={styles.status}>
                              <p>Page Id</p>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {handleAvailableBusinessPages()?.map((item, i) => (
                            <tr key={i}>
                              <td className={styles.status}>
                                <p>{item?.label}</p>
                              </td>
                              <td className={styles.status}>
                                <p>{item?.value}</p>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <div className={styles.status_message}>
                        <p>{loadBusinessErrors[0]?.message || 'Business not found'}</p>
                      </div>
                    )}
                  </div>
                </div>
              </Fragment>
            )}
          </Col>
        </Row>
      </div>
    </AuthLayout>
  );
};

export default Profile;
