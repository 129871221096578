import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../Config';

const initialState = {
  isLoading: false,
  isError: null,
  errors: [],
  isSuccess: false,
  message: '',
};

export const ProfileDelete = createAsyncThunk(
  'linkedin/profile-delete',
  async ({ id, onSuccess }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.delete(`${BASE_URL}/api/linkedin/delete-profile/${id}`);
      onSuccess && onSuccess(response?.data);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const linkedinProfileDeleteSlice = createSlice({
  name: 'Linkedin Profile Delete',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ProfileDelete.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(ProfileDelete.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.errors = [];
      state.isSuccess = action?.payload?.success;
      state.message = action?.payload?.message;
    });
    builder.addCase(ProfileDelete.rejected, (state, action) => {
      state.isLoading = false;
      state.errors = action.payload?.errors || [];
      state.isError = true;
    });
  },
});

export default linkedinProfileDeleteSlice?.reducer;
