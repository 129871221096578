import React, { useState, useEffect, useRef } from 'react';
import styles from './FilterElement.module.scss';
import cc from 'classnames';
import { Select, Input, Tag, Tooltip } from 'antd';
import RadioButton from '../../Commons/RadioButton/RadioButton';

const FilterElement = ({
  title,
  description,
  tagClassName,
  inputClassName,
  elementType,
  type,
  data,
  onSuccess,
  selectOptions,
  error,
  onChange,
  selectLoading,
  onSelectClear,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [inputVisible, setInputVisible] = useState(false);
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');
  const [selectValue, setSelectValue] = useState(undefined);
  const inputRef = useRef(null);
  const editInputRef = useRef(null);

  const [tags, setTags] = useState({
    include: undefined,
    is_disabled: false,
    items: [],
  });

  useEffect(() => {
    if (data && data?.items) {
      setTags(data);
    }
  }, [data]);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);

  const handleClose = (index) => {
    const newTags = tags?.items?.filter((tag, i) => i !== index);
    let dataToUpdate;
    dataToUpdate = { ...tags, items: newTags };

    if (!newTags[0]) {
      dataToUpdate = { ...tags, items: newTags, include: undefined };
    }
    setTags(dataToUpdate);
    onSuccess && onSuccess(dataToUpdate);
  };

  const handleEditInputChange = (e) => {
    onChange && onChange(e?.target?.value);
    setEditInputValue(e?.target?.value?.toLowerCase());
  };

  const handleEditInputConfirm = () => {
    if (!editInputValue) {
      return;
    }
    const newTags = [...tags?.items];
    newTags[editInputIndex] = editInputValue;
    setTags({ ...tags, items: newTags });
    onSuccess && onSuccess({ ...tags, items: newTags });
    setEditInputIndex(-1);
    setInputValue('');
  };

  const handleRadioChange = (name, checked) => {
    if (name === 'is_disabled') {
      setTags({ ...tags, items: [], [name]: checked, include: undefined });
      onSuccess && onSuccess({ ...tags, items: [], [name]: checked, include: undefined });
      return;
    }
    setTags({ ...tags, [name]: checked, is_disabled: false });
    onSuccess && onSuccess({ ...tags, [name]: checked, is_disabled: false });
  };

  const includeHandler = (value, label) => {
    if (label === 'include' && Boolean(value) === true) {
      return value;
    }
    if (label === 'exclude' && value !== undefined && value === false) {
      return !value;
    }
    if (label === 'is_disabled' && value !== undefined && value === true) {
      return value;
    }
  };

  const handleOnSelect = (value) => {
    setSelectValue(null);
    let newTags = { ...tags };

    if (tags?.items?.length > 0) {
      newTags = { ...newTags, items: [...tags?.items, value] };
    } else {
      newTags = { ...newTags, items: [...newTags?.items, value], include: true, is_disabled: false };
    }
    setTags(newTags);
    onSuccess && onSuccess(newTags);
  };

  const handleTypeChanger = () => {
    if (tags?.items?.length > 0) return;
    setTags({ ...tags, include: true, is_disabled: false });
    onSuccess && onSuccess({ ...tags, include: true, is_disabled: false });
  };

  return (
    <div className={cc(styles.custom_tags_container, error && styles.tags_error)}>
      <div className={styles.header_details_container}>
        <div className={styles.title}>
          <h6>{title}</h6>
          <p>{description}</p>
        </div>
        <div className={styles.check_location_container}>
          <div className={cc(styles.include, styles.active)}>
            <RadioButton
              name={title}
              onChange={(e) => handleRadioChange('include', e?.target?.checked)}
              checked={includeHandler(tags?.include, 'include')}
            />
            <p>Include</p>
          </div>
          <div className={cc(styles.exclude, styles.active)}>
            <RadioButton
              name={title}
              onChange={(e) => handleRadioChange('include', !e?.target?.checked)}
              checked={includeHandler(tags?.include, 'exclude')}
            />
            <p>Exclude</p>
          </div>
          <div className={cc(styles.exclude, styles.active)}>
            <RadioButton
              name={title}
              onChange={(e) => handleRadioChange('is_disabled', e?.target?.checked)}
              checked={includeHandler(tags?.is_disabled, 'is_disabled')}
            />
            <p>Disabled</p>
          </div>
        </div>
      </div>
      <div className={styles.tags_container}>
        {tags?.items?.map((tag, index) => {
          if (editInputIndex === index && !selectOptions) {
            return (
              <div className={styles.editable_input} key={index}>
                <Input
                  ref={editInputRef}
                  size="small"
                  value={editInputValue}
                  onChange={handleEditInputChange}
                  onBlur={handleEditInputConfirm}
                  onPressEnter={handleEditInputConfirm}
                />
              </div>
            );
          }
          const isLongTag = tag.length > 20;
          const tagElem = (
            <Tag
              key={index}
              className={`tag ${tagClassName}`}
              closable
              style={{
                display: 'inline-block',
              }}
              onClose={() => handleClose(index)}
            >
              <span
                onDoubleClick={(e) => {
                  setEditInputIndex(index);
                  setEditInputValue(tag);
                  e.preventDefault();
                }}
              >
                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
              </span>
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag} key={index}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
        {elementType === 'select' && (
          <Select
            className={`select_tags_input ${inputClassName}`}
            placeholder={`Add More ${title}...`}
            showSearch
            suffixIcon={false}
            size={'small'}
            options={selectOptions}
            autoClearSearchValue={true}
            onSelect={handleOnSelect}
            loading={selectLoading}
            onClear={onSelectClear}
            value={selectValue}
            onKeyUp={handleTypeChanger}
            allowClear
            style={{
              width: 168,
            }}
          ></Select>
        )}
      </div>
    </div>
  );
};

export default FilterElement;
