import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../Config';

const initialState = {
  isLoading: false,
  isError: null,
  errors: [],
  isSuccess: false,
  message: '',
  sessionId: '',
  status: '',
};

export const linkedinPin = createAsyncThunk(
  'linkedin/pin',
  async ({ data, onSuccess }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(`${BASE_URL}/api/linkedin/session/pin`, data);
      onSuccess && onSuccess();
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const linkedinPinSlice = createSlice({
  name: 'Linkedin Pin',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(linkedinPin.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.message = '';
    });
    builder.addCase(linkedinPin.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.errors = [];
      state.isSuccess = action?.payload?.success;
      state.message = action?.payload?.message;
      state.sessionId = action?.payload?.sessionId;
      state.status = action?.payload?.status;
    });
    builder.addCase(linkedinPin.rejected, (state, action) => {
      state.isLoading = false;
      state.errors = action.payload?.errors || [];
      state.isError = true;
    });
  },
});

export default linkedinPinSlice?.reducer;
