import React, { Fragment, useEffect, useState } from 'react';
import { Button, Image, Modal, notification } from 'antd';
import InputText from '../../Commons/InputText/InputText';
import validator from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import { addLinkedinProfile } from '../../../Redux/Features/addLinkedinProfileSlice';
import { startLinkedinSession } from '../../../Redux/Features/startLinkedinSessionSlice';
import { linkedinSessionStatus, setLinkedinSessionStateNull } from '../../../Redux/Features/linkedinSessionStatusSlice';
import { linkedinPin } from '../../../Redux/Features/linkedinPinSlice';
import CustomModal from '../../Commons/Modal/Modal';
import { ProfileDelete } from '../../../Redux/Features/linkedinProfileDeleteSlice';
import { getCookie, removeCookie, setCookie } from '../../../utils/helper';
import { getLinkedinProfiles } from '../../../Redux/Features/getLinkedinProfilesSlice';
import styles from './AddLinkedinProfile.module.scss';
import ScreenLoad from '../../Commons/ScreenLoad/ScreenLoad';
import './override.scss';
import InputCountry from '../../Commons/InputCountry/InputCountry';
import { loadBusinessPages } from '../../../Redux/Features/loadBusinessPagesSlice';

const AddLinkedinProfile = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state?.user);
  const { isLoading: addProfileLoading } = useSelector((state) => state?.addLinkedinProfile);
  const { isSuccess: startSessionSuccess, sessionId } = useSelector((state) => state?.startLinkedinSession);
  const {
    isSuccess: sessionStatusSuccess,
    isLoading: sessionStatusLoading,
    data,
  } = useSelector((state) => state?.linkedinSessionStatus);
  const [clientError, setClientError] = useState({});
  const [statusCheckLoading, setStatusCheckLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [keyToStartSession, setKeyToStartSession] = useState(false);
  const [pin, setPin] = useState('');
  const [sessionMessage, setSessionMessage] = useState('');
  const [modalBtnLoading, setModalBtnLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    country: 'US',
  });

  useEffect(() => {
    setStatusCheckLoading(false);
    setKeyToStartSession(false);
    setSessionMessage('');
    dispatch(setLinkedinSessionStateNull());
  }, []);

  const setFeild = (feildName, feildValue) => {
    setFormData({ ...formData, [feildName]: feildValue });
  };

  const handleConnect = async () => {
    let isErr = false;
    let errors = {};

    if (!validator.isEmail(formData?.email)) {
      isErr = true;
      errors.email = 'Email is invalid';
    }
    if (!validator.isLength(formData?.password, { min: 6 })) {
      isErr = true;
      errors.password = 'Password should be 6 or more characters long';
    }
    if (validator.isEmpty(formData?.email)) {
      isErr = true;
      errors.email = 'Email is required';
    }
    if (validator.isEmpty(formData?.password)) {
      isErr = true;
      errors.password = 'Password is required';
    }
    if (validator.isEmpty(formData?.country)) {
      isErr = true;
      errors.country = 'Country is required';
    }

    if (isErr) {
      isErr = false;
      setClientError(errors);
    } else {
      setClientError({});
      const data = {
        data: {
          ...formData,
        },
        onSuccess: handleProfileSuccess,
        onError: () => {
          setStatusCheckLoading(false);
        },
      };
      dispatch(addLinkedinProfile(data));
      setStatusCheckLoading(true);
      setOpen && setOpen(false);
    }
  };

  const handleProfileSuccess = (response) => {
    setFormData({
      email: '',
      password: '',
      country: 'US',
    });
    let data = {
      linkedinProfile: {
        id: response?.id,
      },
    };
    setCookie('profileId', response?.id);
    dispatch(startLinkedinSession(data));
    notification.success({ message: 'Status', description: 'Connecting Linkedin...' });
    setKeyToStartSession(true);
    dispatch(setLinkedinSessionStateNull());
  };

  const handleDeletePreviousProfile = () => {
    let id = getCookie('profileId');
    if (!id) return;
    let data = {
      id,
      onSuccess: () => {
        removeCookie('profileId');
      },
    };
    dispatch(ProfileDelete(data));
  };

  useEffect(() => {
    if (!token) return;
    handleDeletePreviousProfile();
  }, [token]);

  useEffect(() => {
    if (!sessionId) return;
    let timeInterval;
    if (
      startSessionSuccess &&
      !sessionStatusLoading &&
      keyToStartSession &&
      data?.status !== 'SUCCESS' &&
      data?.status !== 'FAILED'
    ) {
      timeInterval = setTimeout(() => {
        let data = {
          sessionId,
        };
        dispatch(linkedinSessionStatus(data));
      }, 3000);
    }
    return () => {
      clearTimeout(timeInterval);
    };
  }, [startSessionSuccess, sessionId, sessionStatusSuccess]);

  const handleClosedTheSession = (sessionClosedMessage) => {
    sessionClosedMessage && setSessionMessage(sessionClosedMessage);
    setOpen && setOpen(false);
    setModalBtnLoading(false);
    handleDeletePreviousProfile();
    setStatusCheckLoading(false);
    setIsModalOpen(false);
    setKeyToStartSession(false);
  };

  const onSuccessClosed = () => {
    let profileId = getCookie('profileId');
    dispatch(loadBusinessPages(profileId));
    removeCookie('profileId');
    setModalBtnLoading(false);
    setIsModalOpen(false);
    setStatusCheckLoading(false);
    dispatch(getLinkedinProfiles());
    notification.success({ message: 'Status', description: 'Successfully logged in' });
    setKeyToStartSession(false);
    setOpen && setOpen(false);
  };

  useEffect(() => {
    if (!data) return;
    if (!keyToStartSession) return;
    if (data?.status === 'IN_PROGRESS') {
      setModalBtnLoading(true);
      setSessionMessage('');
    } else if (data?.status === 'WAITING_FOR_PIN') {
      setOpen && setOpen(false);
      setSessionMessage(data?.message);
      setStatusCheckLoading(false);
      setModalBtnLoading(false);
      setIsModalOpen(true);
    } else if (data?.status === 'SUCCESS') {
      onSuccessClosed();
    } else if (data?.status === 'FAILED') {
      handleClosedTheSession(data?.message);
    }
  }, [data]);

  useEffect(() => {
    if (!sessionMessage) return;
    notification.error({ message: 'Status', description: sessionMessage, duration: 0 });
  }, [sessionMessage]);

  const handleConfirmPin = () => {
    let isErr = false;
    let error = {};

    if (!validator.isLength(pin, { min: 6 })) {
      isErr = true;
      error.pin = 'Pin should be 6 characters long';
    }
    if (validator.isEmpty(pin)) {
      isErr = true;
      error.pin = 'Pin is required';
    }
    if (isErr) {
      isErr = false;
      setClientError(error);
    } else {
      setClientError({});
      let data = {
        data: {
          sessionId,
          pin,
        },
        onSuccess: () => {
          setPin('');
          let data = {
            sessionId,
          };
          dispatch(linkedinSessionStatus(data));
        },
      };
      dispatch(linkedinPin(data));
    }
  };

  return (
    <Fragment>
      {statusCheckLoading && <ScreenLoad info={''} />}
      <div className={styles.add_linkedin_profile_container}>
        <Modal
          open={open}
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}
          centered
          closeIcon={false}
          className={'linkedin_modal_container'}
        >
          <div className={styles.linkedin_modal}>
            <div className={styles.header_details_container}>
              <div className={styles.auto_container}>
                <div className={styles.close_icon}>
                  <Image
                    src={'/Assets/close_icon.svg'}
                    alt={'Close icon'}
                    preview={false}
                    onClick={() => {
                      setClientError({});
                      setOpen && setOpen(false);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={styles.form_container}>
              <InputCountry
                size={'medium'}
                label={'Linkedin Account Country'}
                placeholder={'Country'}
                value={formData?.country}
                onChange={(e) => setFeild('country', e?.value)}
                defaultValue={formData?.country}
                error={clientError?.country ? clientError?.country : false}
              />
              <div className={styles.divider}></div>
              <InputText
                size="medium"
                type={'email'}
                placeholder={'Email'}
                label={'Linkedin Email'}
                value={formData?.email}
                onChange={(e) => setFeild('email', e?.target?.value)}
                error={clientError?.email ? clientError?.email : false}
              />
              <div className={styles.divider}></div>
              <InputText
                size="medium"
                type={'password'}
                placeholder={'Password'}
                label={'Linkedin Password'}
                value={formData?.password}
                onChange={(e) => setFeild('password', e?.target?.value)}
                error={clientError?.password ? clientError?.password : false}
              />
              <div className={styles.button_container}>
                <Button
                  className="ant-btn-middle"
                  type="primary"
                  loading={addProfileLoading}
                  disabled={addProfileLoading}
                  onClick={handleConnect}
                >
                  Add LinkedIn
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      {isModalOpen && modalBtnLoading ? (
        <ScreenLoad info={'We are trying to log you in'} />
      ) : (
        <CustomModal
          isOpen={isModalOpen}
          title={'Confirm Your Pin'}
          description={'Enter your pin to continue'}
          okText={'Confirm'}
          onSubmit={() => handleConfirmPin()}
          onCancel={() => handleClosedTheSession()}
          btnLoading={modalBtnLoading}
        >
          <InputText
            label={'Pin'}
            type={'number'}
            placeholder={'Enter your pin'}
            value={pin}
            onChange={(e) => setPin(e?.target?.value)}
            error={clientError?.pin ? clientError?.pin : false}
          />
        </CustomModal>
      )}
    </Fragment>
  );
};

export default AddLinkedinProfile;
