import React, { useEffect, useState } from 'react';
import cc from 'classnames';
import styles from './TabsBar.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userLogout } from '../../Redux/Features/userSlice';

const TabsBar = ({ content, list }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [tabsItem, setTabsItem] = useState([]);

  const defaultList = [
    {
      id: 0,
      title: 'Profile',
      active: false,
      keys: ['/'],
      onClick: () => navigate('/'),
    },
    {
      id: 1,
      title: 'LinkedIn Accounts',
      active: false,
      keys: ['/linkedin-profiles', '/linkedin-profiles/profile'],
      onClick: () => navigate(`/linkedin-profiles`),
    },
    {
      id: 2,
      title: 'Campaigns',
      active: false,
      keys: ['/campaigns', '/campaign', '/create-campaign'],
      onClick: () => navigate('/campaigns'),
    },
    {
      id: 3,
      title: 'Logout',
      active: false,
      keys: [],
      onClick: () => handleLogout(),
    },
  ];

  useEffect(() => {
    let newList = [];
    if (list) {
      newList = list;
    } else {
      newList = defaultList;
    }

    const pathName = `/${location?.pathname?.split('/')[1]}`;
    let copyTabsItem = [...newList];
    const isKeyMatched = copyTabsItem?.find((item) => item?.keys?.includes(pathName));
    handleActive(isKeyMatched?.id, copyTabsItem);
  }, [list]);

  const styleHandler = (isActive) => {
    return isActive ? cc(styles.item, styles.item_active) : styles.item;
  };

  const handleActive = (i, tabsItem) => {
    let copyTabsItem = tabsItem;
    let newTabsItem = [];
    copyTabsItem?.filter((item) => newTabsItem?.push({ ...item, active: false }));
    if (newTabsItem[i]?.active !== undefined) {
      newTabsItem[i].active = true;
      setTabsItem(newTabsItem);
    }
  };

  const handleLogout = () => {
    dispatch(userLogout());
    navigate(`/login`);
  };

  return (
    <div className={styles.tabsbar_contianer}>
      <div className={styles.item_container}>
        {tabsItem[0] &&
          tabsItem?.map((item, i) => (
            <div
              className={styleHandler(item?.active)}
              key={i}
              onClick={() => {
                item?.onClick();
              }}
            >
              <p>{item?.title}</p>
            </div>
          ))}
      </div>
      {content}
    </div>
  );
};

export default TabsBar;
