import React from 'react';
import styles from './ScreenLoad.module.scss';
import Spinner from '../Spinner/Spinner';

const ScreenLoad = ({ info }) => {
  return (
    <div className={styles.screen_load_container}>
      <div className={styles.loading_state_container}>
        <Spinner size={80} />
        {info && <h4 className={styles.waiting_info}>{info}</h4>}
      </div>
    </div>
  );
};

export default ScreenLoad;
