import React, { useState } from 'react';
import { Input } from 'antd';
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import cc from 'classnames';
import styles from './InputText.module.scss';

const InputText = ({
  label,
  type = '',
  onChange,
  value,
  onBlur,
  maxLength,
  placeholder,
  onFocus,
  error = false,
  name,
  defaultValue,
  size = 'large',
  status,
  disabled,
  onPressEnter,
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handlePasswordAddon = () => {
    return type === 'password' ? (
      passwordVisible ? (
        <EyeTwoTone onClick={() => setPasswordVisible(false)} />
      ) : (
        <EyeInvisibleOutlined onClick={() => setPasswordVisible(true)} />
      )
    ) : (
      ''
    );
  };

  const handleTypePassword = (type) => {
    if (type === 'password' && !passwordVisible) return 'password';
    if (type === 'password' && passwordVisible) return 'text';
    return type;
  };

  const styleHandler = () => {
    const textAllowedTypes = ['text', 'email', 'password', 'number', ''];
    if (textAllowedTypes?.includes(type) && size === 'large') {
      return styles.input;
    } else if (type === 'checkbox') {
      return styles.checkbox_input;
    } else if (textAllowedTypes?.includes(type) && size === 'medium') {
      return styles.input_medium;
    }
  };

  return (
    <div className={cc(styles.new_input_container, styleHandler())}>
      {label && <label className={`${error ? styles.danger_label : ''}`}>{label}</label>}
      <div className={styleHandler()}>
        <Input
          type={handleTypePassword(type)}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          maxLength={maxLength}
          placeholder={placeholder}
          onFocus={onFocus}
          name={name}
          defaultValue={defaultValue}
          size={size}
          status={status || error ? 'error' : ''}
          disabled={disabled}
          addonAfter={handlePasswordAddon()}
          onPressEnter={onPressEnter}
        />
        {error && <small className={styles.error_message}>{error}</small>}
      </div>
    </div>
  );
};

export default InputText;
