import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import { notification } from 'antd';

const initialState = {
  isLoading: false,
  isError: null,
  errors: [],
  isSuccess: false,
  message: '',
};

export const pauseCampaign = createAsyncThunk('campaign/pause-campaign', async (id, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/campaign/pause-campaign/${id}`);
    response?.data?.message && notification.success({ message: 'Success', description: response?.data?.message });
    return response?.data;
  } catch (error) {
    notification.error({
      message: 'Error',
      description: error?.response?.data?.errors[0]?.message || 'Network error',
    });
    return rejectWithValue(error?.response?.data);
  }
});

const pauseCampaignSlice = createSlice({
  name: 'Pause Campaign',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(pauseCampaign.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = false;
    });
    builder.addCase(pauseCampaign.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.errors = [];
      state.isSuccess = action?.payload?.success;
      state.message = action?.payload?.message;
    });
    builder.addCase(pauseCampaign.rejected, (state, action) => {
      state.isLoading = false;
      state.errors = action.payload?.errors || [];
      state.isError = true;
    });
  },
});

export default pauseCampaignSlice?.reducer;
