import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import { getLinkedinProfiles } from './getLinkedinProfilesSlice';
import { notification } from 'antd';

const initialState = {
  isLoading: false,
  isError: null,
  errors: [],
  isSuccess: false,
  message: '',
  profile: {},
};

export const loadBusinessPages = createAsyncThunk(
  'linkedin/get-business-pages',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/linkedin/business-pages/${id}`);

      response?.data?.message && notification.success({ message: 'Success', description: response?.data?.message });

      dispatch(getLinkedinProfiles());
      return response?.data;
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error?.response?.data?.errors[0]?.message || 'Network error',
      });
      return rejectWithValue(error?.response?.data);
    }
  }
);

const loadBusinessPagesSlice = createSlice({
  name: 'Get Linkedin Business Pages',
  initialState: initialState,
  reducers: {
    clearErrorsState: (state, action) => {
      state.errors = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadBusinessPages.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(loadBusinessPages.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.errors = [];
      state.isSuccess = action?.payload?.success;
      state.message = action?.payload?.message;
      state.profile = action?.payload?.profile;
    });
    builder.addCase(loadBusinessPages.rejected, (state, action) => {
      state.isLoading = false;
      state.errors = action.payload?.errors || [];
      state.isError = true;
    });
  },
});

export const { clearErrorsState } = loadBusinessPagesSlice?.actions;

export default loadBusinessPagesSlice?.reducer;
