import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../Config';

const initialState = {
  isLoading: false,
  isError: null,
  errors: [],
  isSuccess: false,
  message: '',
  products: [],
};

export const getProducts = createAsyncThunk('Product/get-products', async (data, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/product/get-products`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

const getProductsSlice = createSlice({
  name: 'Get Products',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProducts.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(getProducts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.errors = [];
      state.isSuccess = action?.payload?.success;
      state.message = action?.payload?.message;
      state.products = action?.payload?.products;
    });
    builder.addCase(getProducts.rejected, (state, action) => {
      state.isLoading = false;
      state.errors = action.payload?.errors || [];
      state.products = [];
      state.isError = true;
    });
  },
});

export default getProductsSlice?.reducer;
