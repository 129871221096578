import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import { notification } from 'antd';

const initialState = {
  isLoading: false,
  isError: null,
  errors: [],
  isSuccess: false,
  message: '',
  sessionUrl: '',
};

export const updateSubscription = createAsyncThunk(
  'campaign/update-subscription',
  async ({ id, data, onSuccess }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(`${BASE_URL}/api/campaign/customer-portal/${id}`, data);
      response?.data?.message && notification.success({ message: 'Success', description: response?.data?.message });
      onSuccess && onSuccess(response?.data?.sessionUrl);
      return response?.data;
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error?.response?.data?.errors[0]?.message || 'Network error',
      });
      return rejectWithValue(error?.response?.data);
    }
  }
);

const updateSubscriptionSlice = createSlice({
  name: 'Update Campaign Subscription',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateSubscription.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = false;
    });
    builder.addCase(updateSubscription.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.errors = [];
      state.isSuccess = action?.payload?.success;
      state.message = action?.payload?.message;
      state.sessionUrl = action?.payload?.sessionUrl;
    });
    builder.addCase(updateSubscription.rejected, (state, action) => {
      state.isLoading = false;
      state.sessionUrl = '';
      state.errors = action.payload?.errors || [];
      state.isError = true;
    });
  },
});

export default updateSubscriptionSlice?.reducer;
