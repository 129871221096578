import React from 'react';
import { Spin } from 'antd';
import cc from 'classnames';
import styles from './Spinner.module.scss';
import { LoadingOutlined } from '@ant-design/icons';

const Spinner = ({ type = 'primary', size }) => {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: size,
      }}
      spin
    />
  );
  return (
    <span className={cc(styles.spinner_container, styles[type])}>
      <Spin indicator={antIcon} size={size} />
    </span>
  );
};

export default Spinner;
