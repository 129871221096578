import React, { Fragment, useEffect, useState } from 'react';
import { Button, Image, Modal, notification } from 'antd';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getLinkedinProfiles } from '../../../Redux/Features/getLinkedinProfilesSlice';
import { ProfileDelete } from '../../../Redux/Features/linkedinProfileDeleteSlice';
import { getLinkedinProfileById } from '../../../Redux/Features/getLinkedinProfileSlice';
import styles from './DeleteLinkedinProfile.module.scss';
import ScreenLoad from '../../Commons/ScreenLoad/ScreenLoad';

const DeleteLinkedinProfile = ({ open, setOpen }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state?.getLinkedinProfile);
  const { isLoading: updateProfileLoading } = useSelector((state) => state?.updateLinkedinProfile);
  const [statusCheckLoading, setStatusCheckLoading] = useState(false);
  useEffect(() => {
    dispatch(getLinkedinProfileById(id));
  }, [id]);


  const triggerDelete = async () => {
    let isErr = false;
    let errors = {};

    if (isErr) {
      isErr = false;
    } else {
      const data = {
        data: {
          linkedin_profile_id: profile._id
        },
        onSuccess: onSuccessClosed,
        onError: () => {
          console.error("error happened while deleting profile");
        },
        id,
      };
      dispatch(ProfileDelete(data));
      setOpen && setOpen(false);
    }
  };

  const onSuccessClosed = () => {
    setOpen(false);
    dispatch(getLinkedinProfiles());
    notification.success({ message: 'Status', description: 'Profile successfully deleted' });
    setOpen && setOpen(false);
  };

  return (
    <Fragment>
      {statusCheckLoading && <ScreenLoad info={''} />}
      <div className={styles.add_linkedin_profile_container}>
        <Modal
          open={open}
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}
          centered
          closeIcon={false}
          className={'linkedin_modal_container'}
        >
          <div className={styles.linkedin_modal}>
            <div className={styles.header_details_container}>
              <div className={styles.auto_container}>
                <div className={styles.user_details}>
                  <Image
                    src={profile?.linkedin_profile_pic ? profile?.linkedin_profile_pic : '/Assets/placeholder.png'}
                    alt={'user default image'}
                    preview={false}
                    onError={(e) => {
                      e.target.src = '/Assets/placeholder.png';
                    }}
                  />
                  <h2>{profile?.linkedin_name ? profile?.linkedin_name : 'N/A'}</h2>
                </div>
                <div className={styles.close_icon}>
                  <Image
                    src={'/Assets/close_icon.svg'}
                    alt={'Close icon'}
                    preview={false}
                    onClick={() => {
                      setOpen && setOpen(false);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={styles.form_container}>
              Are you sure you want to delete this profile?
              <Button
                className="ant-btn-middle"
                type="primary"
                danger
                loading={updateProfileLoading}
                disabled={updateProfileLoading}
                onClick={triggerDelete}
              >
                Delete Profile
              </Button>
            </div>

          </div>
        </Modal>
      </div>
    </Fragment>
  );
};

export default DeleteLinkedinProfile;
