import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import { notification } from 'antd';

const initialState = {
  isLoading: false,
  isError: null,
  errors: [],
  isSuccess: false,
  message: '',
};

export const setNewPassword = createAsyncThunk(
  'user/set-password',
  async ({ data, navigate, onSuccess }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(`${BASE_URL}/api/auth/user/set-password`, data);
      response?.data?.message && notification?.success(response?.data?.message);
      onSuccess && onSuccess();
      navigate && navigate(`/login`);
      return response?.data;
    } catch (error) {
      notification?.error({
        message: 'Error',
        description: error?.response?.data?.errors[0]?.message || 'Network error',
      });
      return rejectWithValue(error?.response?.data);
    }
  }
);

const setNewPasswordSlice = createSlice({
  name: 'Set New Password Slice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setNewPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(setNewPassword.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.errors = [];
      state.isSuccess = action.payload?.success;
      state.message = action.payload?.message;
    });
    builder.addCase(setNewPassword.rejected, (state, action) => {
      state.isLoading = false;
      state.errors = action.payload?.errors || [];
      state.isError = true;
    });
  },
});

export default setNewPasswordSlice?.reducer;
