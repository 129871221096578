import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import { notification } from 'antd';

const initialState = {
  isLoading: false,
  isError: null,
  errors: [],
  isSuccess: false,
  message: '',
  sessionUrl: '',
  campaign: {},
};

export const createCampaign = createAsyncThunk(
  'campaign/create-campaign',
  async ({ data, onSuccess, onError }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(`${BASE_URL}/api/campaign/create-campaign`, data);
      response?.data?.message && notification.success({ message: 'Success', description: response?.data?.message });

      onSuccess && onSuccess(response?.data?.sessionUrl);

      return response?.data;
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error?.response?.data?.errors[0]?.message || 'Network error',
      });
      onError && onError();
      return rejectWithValue(error?.response?.data);
    }
  }
);

const createCampaignSlice = createSlice({
  name: 'Create Campaign',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createCampaign.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = false;
    });
    builder.addCase(createCampaign.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.errors = [];
      state.isSuccess = action?.payload?.success;
      state.message = action?.payload?.message;
      state.sessionUrl = action?.payload?.sessionUrl;
      state.campaign = action?.payload?.campaign;
    });
    builder.addCase(createCampaign.rejected, (state, action) => {
      state.isLoading = false;
      state.errors = action.payload?.errors || [];
      state.isError = true;
    });
  },
});

export default createCampaignSlice?.reducer;
