import React from 'react';
import styles from './RadioButton.module.scss';

const RadioButton = ({ name, onChange, checked = false }) => {
  return (
    <div className={styles.radio_button_container}>
      <input type="radio" name={name} checked={checked} onChange={onChange} />
    </div>
  );
};

export default RadioButton;
