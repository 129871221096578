import React, { useState, useEffect, useRef } from 'react';
import styles from './TargetElement.module.scss';
import cc from 'classnames';
import { Image, Select, Input, Tag, Tooltip, Avatar, Space, Spin } from 'antd';
import { encodeText } from '../../../utils/helper';

const TargetElement = ({
  title,
  description,
  tagClassName,
  inputClassName,
  type,
  data,
  onSuccess,
  selectOptions,
  error,
  onChange,
  selectLoading,
  onSelectSearchEnter,
  onSelectClear,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [inputVisible, setInputVisible] = useState(false);
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');
  const [selectOptionsArr, setSelectOptionsArr] = useState([]);
  const [selectValue, setSelectValue] = useState(null);
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const inputRef = useRef(null);
  const editInputRef = useRef(null);

  const [tags, setTags] = useState({
    include: undefined,
    items: [],
  });

  useEffect(() => {
    if (data && data?.items) {
      setTags(data);
    }
  }, [data]);

  useEffect(() => {
    setSelectOptionsArr(selectOptions);
  }, [selectOptions]);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);

  const handleClose = (index) => {
    const newTags = tags?.items?.filter((tag, i) => i !== index);
    let dataToUpdate;
    dataToUpdate = { ...tags, items: newTags };

    if (!newTags[0]) {
      dataToUpdate = { ...tags, items: newTags, include: undefined };
    }
    setTags(dataToUpdate);
    onSuccess && onSuccess(dataToUpdate);
  };

  const handleInputChange = (e) => {
    onChange && onChange(e?.target?.value);
    setInputValue(e?.target?.value?.toLowerCase());
  };

  const handleInputConfirm = () => {
    if (inputValue && tags?.items?.indexOf(inputValue) === -1) {
      setTags({ ...tags, items: [...tags?.items, inputValue] });
      onSuccess && onSuccess({ ...tags, items: [...tags?.items, inputValue] });
    }
    setInputVisible(false);
    setInputValue('');
  };

  const handleEditInputChange = (e) => {
    onChange && onChange(e?.target?.value);
    setEditInputValue(e?.target?.value?.toLowerCase());
  };

  const handleEditInputConfirm = () => {
    if (!editInputValue) {
      return;
    }
    const newTags = [...tags?.items];
    newTags[editInputIndex] = editInputValue;
    setTags({ ...tags, items: newTags });
    onSuccess && onSuccess({ ...tags, items: newTags });
    setEditInputIndex(-1);
    setInputValue('');
  };

  const handleOnSelect = (value) => {
    if (title?.toLowerCase() === 'accounts' || title?.toLowerCase() === 'pages') {
      let findOption = selectOptions?.find((item, i) => item?.value === value);
      setTags({ ...tags, items: [...tags?.items, findOption] });
      onSuccess && onSuccess({ ...tags, items: [...tags?.items, findOption] });
      setSelectValue(null);
      resetSelect();
    } else {
      setTags({ ...tags, items: [...tags?.items, value] });
      onSuccess && onSuccess({ ...tags, items: [...tags?.items, value] });
    }
  };

  const resetSelect = () => {
    setSelectOptionsArr([]);
    setDropDownOpen(false);
  };

  return (
    <div className={cc(styles.custom_tags_container, error && styles.tags_error)}>
      <div className={styles.header_details_container}>
        <div className={styles.title}>
          <h6>{title}</h6>
          <p>{description}</p>
        </div>
      </div>
      <div className={styles.tags_container}>
        {tags?.items?.map((tag, index) => {
          if (editInputIndex === index && !selectOptions) {
            return (
              <div className={styles.editable_input} key={index}>
                <Input
                  ref={editInputRef}
                  size="small"
                  value={editInputValue}
                  onChange={handleEditInputChange}
                  onBlur={handleEditInputConfirm}
                  onPressEnter={handleEditInputConfirm}
                />
              </div>
            );
          }
          const isOrg = title?.toLowerCase() === 'pages' || title?.toLowerCase() === 'accounts';
          const isLongTag = isOrg ? tag?.name?.length > 20 : tag.length > 20;
          const tagElem = (
            <Tag
              key={index}
              className={`tag ${tagClassName}`}
              closable
              style={{
                display: 'inline-block',
              }}
              onClose={() => handleClose(index)}
            >
              <span
                onDoubleClick={(e) => {
                  setEditInputIndex(index);
                  setEditInputValue(isOrg ? tag?.name : tag);
                  e.preventDefault();
                }}
              >
                {!isOrg ? (isLongTag ? `${tag.slice(0, 20)}...` : tag) : ''}
                <Space>
                  {isOrg ? (
                    <Avatar
                      src={
                        <Image
                          preview={false}
                          src={tag?.img ? tag?.img : '/Assets/placeholder.png'}
                          onError={(img) => {
                            img.target.src = '/Assets/placeholder.png';
                          }}
                        />
                      }
                    />
                  ) : (
                    ''
                  )}
                  {isOrg ? (isLongTag ? `${tag?.name?.slice(0, 20)}...` : tag?.name) : ''}
                </Space>
              </span>
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={isOrg ? tag?.name : tag} key={index}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
        {type === 'input' ? (
          <Input
            ref={inputRef}
            type="text"
            size="small"
            placeholder={`Add More ${title}...`}
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
            className={`tags_input ${inputClassName}`}
          />
        ) : (
          <Select
            className={`select_tags_input ${inputClassName}`}
            placeholder={`Add More ${title}...`}
            showSearch
            suffixIcon={false}
            allowClear
            size={'small'}
            open={dropdownOpen}
            options={selectOptionsArr?.length > 0 ? selectOptionsArr : []}
            value={selectValue}
            onSelect={handleOnSelect}
            optionLabelProp={'name'}
            optionFilterProp={'name'}
            onSearch={(value) => {
              onChange && onChange(value);
              if (value?.length <= 0) {
                resetSelect();
              }
            }}
            onClear={(ele) => {
              onSelectClear && onSelectClear(ele);
              resetSelect();
            }}
            notFoundContent={
              selectLoading ? (
                <Space style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Spin size="small" />
                </Space>
              ) : undefined
            }
            onKeyUp={(e) => {
              let value = e?.target?.value;
              if (value && e?.key === 'Enter') {
                setDropDownOpen(true);
                onSelectSearchEnter(encodeText(value));
              }
            }}
            optionRender={(item) => (
              <Space>
                <Avatar
                  src={
                    <Image
                      preview={false}
                      src={item?.data?.img ? item?.data?.img : '/Assets/placeholder.png'}
                      onError={(img) => {
                        img.target.src = '/Assets/placeholder.png';
                      }}
                    />
                  }
                />
                <span>{`${item?.data?.name}`}</span>
              </Space>
            )}
          />
        )}
      </div>
    </div>
  );
};

export default TargetElement;
