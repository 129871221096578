import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import { notification } from 'antd';

const initialState = {
  isLoading: false,
  isError: null,
  errors: [],
  isSuccess: false,
  message: '',
  campaign: {},
};

export const getCampaign = createAsyncThunk('Campaign/get-campaign', async ({ id, onError }, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/campaign/campaign/${id}`);
    return response?.data;
  } catch (error) {
    notification.error({
      message: 'Error',
      description: error?.response?.data?.errors[0]?.message || 'Network error',
    });
    if (onError) {
      onError(error?.response?.status);
    }
    return rejectWithValue(error?.response?.data);
  }
});

const getCampaignSlice = createSlice({
  name: 'Get Campaign',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCampaign.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(getCampaign.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.errors = [];
      state.isSuccess = action?.payload?.success;
      state.message = action?.payload?.message;
      state.campaign = action?.payload?.campaign;
    });
    builder.addCase(getCampaign.rejected, (state, action) => {
      state.isLoading = false;
      state.errors = action.payload?.errors || [];
      state.isError = true;
    });
  },
});

export default getCampaignSlice?.reducer;
