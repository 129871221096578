import React, { useEffect, useMemo, useRef, useState } from 'react';
import cc from 'classnames';
import countryList from 'react-select-country-list';
import Select from 'react-select';
import styles from './InputCountry.module.scss';
import './override.scss';

const InputCountry = ({ label, onChange, value, error = false, placeholder = '', defaultValue, size }) => {
  const options = useMemo(() => countryList().getData(), []);
  const [selectedCountry, setSelectedCountry] = useState({});
  const selectRef = useRef();

  useEffect(() => {
    if (!value) {
      setSelectedCountry({});
      return;
    }
    let countryArr = countryList()?.getData();
    setSelectedCountry(countryArr?.filter((item) => item?.label === value || item?.value === value));
  }, [value]);

  useEffect(() => {
    if (!defaultValue) return;
    let country;
    country = countryList()?.getLabel(defaultValue);
    if (!country) {
      country = countryList()?.getValue(defaultValue);
    }
    let countryArr = countryList()?.getData();
    setSelectedCountry(countryArr?.filter((item) => item?.label === country || item?.value === country));
  }, [defaultValue]);

  useEffect(() => {
    selectRef.current.controlRef.id = error ? 'danger_select_container' : 'select_container';
  }, [error]);

  return (
    <div className={cc(styles.new_input_container, 'new_input_container')}>
      {label && <label className={`${error ? styles.danger_label : ''}`}>{label}</label>}
      <div className={styles.input}>
        <Select
          options={options}
          value={selectedCountry}
          onChange={onChange}
          placeholder={placeholder}
          defaultValue={defaultValue}
          ref={selectRef}
        />
        {error && <small className={styles.error_message}>{error}</small>}
      </div>
    </div>
  );
};

export default InputCountry;
