import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../Config';

const initialState = {
  isLoading: false,
  isError: null,
  errors: [],
  isSuccess: false,
  data: {},
};

export const linkedinSessionStatus = createAsyncThunk(
  'linkedin/session-status',
  async ({ sessionId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/linkedin/session/status/${sessionId}`);
      return response?.data;
    } catch (error) {
      console.log(error?.response?.data);
      return rejectWithValue(error?.response?.data);
    }
  }
);

const linkedinSessionStatusSlice = createSlice({
  name: 'Linkedin Session Status',
  initialState: initialState,
  reducers: {
    setLinkedinSessionIsSuccess: (state, action) => {
      state.isSuccess = action?.payload;
      state.message = '';
    },
    setLinkedinSessionData: (state, action) => {
      state.data = action?.payload;
    },
    setLinkedinSessionStateNull: (state, action) => {
      state.isLoading = false;
      state.isError = null;
      state.errors = [];
      state.isSuccess = false;
      state.data = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(linkedinSessionStatus.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(linkedinSessionStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.errors = [];
      state.isSuccess = action?.payload?.success;
      state.data = action?.payload;
    });
    builder.addCase(linkedinSessionStatus.rejected, (state, action) => {
      state.isLoading = false;
      state.errors = action.payload?.errors || [];
      state.isError = true;
    });
  },
});

export const { setLinkedinSessionIsSuccess, setLinkedinSessionData, setLinkedinSessionStateNull } =
  linkedinSessionStatusSlice.actions;

export default linkedinSessionStatusSlice?.reducer;
