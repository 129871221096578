import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../Config';

const initialState = {
  isLoading: false,
  isError: null,
  errors: [],
  isSuccess: false,
  message: '',
  profiles: [],
};

export const getLinkedinProfiles = createAsyncThunk('linkedin/get-profiles', async (data, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/linkedin`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

const getLinkedinProfilesSlice = createSlice({
  name: 'Get Linkedin Profiles',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLinkedinProfiles.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(getLinkedinProfiles.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.errors = [];
      state.isSuccess = action?.payload?.success;
      state.message = action?.payload?.message;
      state.profiles = action?.payload?.profiles;
    });
    builder.addCase(getLinkedinProfiles.rejected, (state, action) => {
      state.isLoading = false;
      state.errors = action.payload?.errors || [];
      state.profiles = [];
      state.isError = true;
    });
  },
});

export default getLinkedinProfilesSlice?.reducer;
