import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AuthLayout from '../../Layouts/AuthLayout/AuthLayout';
import styles from './Home.module.scss';
import SettingsHeader from '../../Components/SettingsHeader/SettingsHeader';
import { Button, Col, Image, Row, Badge, Card, Space } from 'antd';
import InputText from '../../Components/Commons/InputText/InputText';
import validator from 'validator';
import { updateProfile } from '../../Redux/Features/updateProfileSlice';
import InputCountry from '../../Components/Commons/InputCountry/InputCountry';
import { changePassword } from '../../Redux/Features/changePasswordSlice';
import Modal from '../../Components/Commons/Modal/Modal';

const Home = () => {
  const dispatch = useDispatch();
  const { user, token } = useSelector((state) => state?.user);
  const { isSuccess: isPasswordChangeSuccessfully, isLoading: changingPasswordLoading } = useSelector(
    (state) => state?.changePassword
  );
  const { isLoading: updateProfileLoading } = useSelector((state) => state?.updateProfile);
  const [clientError, setClientError] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [passwordFormData, setPasswordFormData] = useState({
    current_password: '',
    new_password: '',
    confirm_password: '',
  });
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    country: '',
    company: '',
    company_size: '',
  });

  const setFormFeildData = (feildName, feildValue) => {
    setFormData({ ...formData, [feildName]: feildValue });
  };

  const setPasswordFormFeildData = (feildName, feildValue) => {
    setPasswordFormData({ ...passwordFormData, [feildName]: feildValue });
  };

  useEffect(() => {
    setFormData({
      name: user?.profile?.name ? user?.profile?.name : '',
      email: user?.profile?.email ? user?.profile?.email : '',
      country: user?.profile?.country ? user?.profile?.country : '',
      company: user?.profile?.company ? user?.profile?.company : '',
      company_size: user?.profile?.company_size ? user?.profile?.company_size : '',
    });
  }, [user]);

  const handleProfileSave = () => {
    let isErr = false;
    let errors = {};

    if (validator.isEmpty(formData?.name)) {
      isErr = true;
      errors.name = 'Name is required';
    }
    if (validator.isEmpty(formData?.country)) {
      isErr = true;
      errors.country = 'Country is required';
    }

    if (isErr) {
      isErr = false;
      setClientError(errors);
    } else {
      setClientError({});
      let data = {
        data: {
          name: formData?.name,
          country: formData?.country,
          company: formData?.company,
          company_size: formData?.company_size,
        },
        token,
      };
      dispatch(updateProfile(data));
    }
  };

  const handleChangePassword = () => {
    let isErr = false;
    let errors = {};
    if (!validator.isLength(passwordFormData?.current_password, { min: 6 })) {
      isErr = true;
      errors.current_password = 'Current Password should be 6 or more characters long';
    }
    if (!validator.isLength(passwordFormData?.new_password, { min: 6 })) {
      isErr = true;
      errors.new_password = 'New Password should be 6 or more characters long';
    }
    if (!validator.isLength(passwordFormData?.confirm_password, { min: 6 })) {
      isErr = true;
      errors.confirm_password = 'Confirm Password should be 6 or more characters long';
    }
    if (validator.isEmpty(passwordFormData?.current_password)) {
      isErr = true;
      errors.current_password = 'Current Password is required';
    }
    if (validator.isEmpty(passwordFormData?.new_password)) {
      isErr = true;
      errors.new_password = 'New Password is required';
    }
    if (validator.isEmpty(passwordFormData?.confirm_password)) {
      isErr = true;
      errors.confirm_password = 'Confirm Password is required';
    }
    if (!validator.equals(passwordFormData?.new_password, passwordFormData?.confirm_password)) {
      isErr = true;
      errors.new_password = 'Password not matched';
      errors.confirm_password = 'Password not matched';
    }
    if (isErr) {
      isErr = false;
      setClientError(errors);
    } else {
      setClientError({});
      let body = {
        current_password: passwordFormData?.current_password,
        new_password: passwordFormData?.new_password,
      };
      dispatch(changePassword(body));
    }
  };

  useEffect(() => {
    if (isOpen) {
      setPasswordFormData({
        current_password: '',
        new_password: '',
        confirm_password: '',
      });
      setClientError({});
    }
  }, [isOpen]);

  useEffect(() => {
    if (isPasswordChangeSuccessfully) {
      setIsOpen(false);
      setPasswordFormData({
        current_password: '',
        new_password: '',
        confirm_password: '',
      });
    }
  }, [isPasswordChangeSuccessfully]);
  return (
    <AuthLayout>
      <SettingsHeader
        content={
          <div className={styles.profile_setting_container}>
            {user?.role !== 'INTERNAL' ? (
              <div className={styles.profile_details}>
                <Image src={'/Assets/placeholder.png'} alt={'user default image'} preview={false} />
                <h2>{user?.profile?.name}</h2>
              </div>
            ) : (
              <Badge.Ribbon text="Internal" style={{ fontStyle: 'italic' }} color="cyan">
                <div className={styles.profile_details}>
                  <Image src={'/Assets/placeholder.png'} alt={'user default image'} preview={false} />
                  <h2>{user?.profile?.name}</h2>
                </div>
              </Badge.Ribbon>
            )}
            <div className={styles.setting_container}>
              {!user?.is_google && (
                <Button type="primary" className="ant-btn-middle" onClick={() => setIsOpen(true)}>
                  Change Password
                </Button>
              )}
            </div>
          </div>
        }
      />
      <Modal
        title={'Change Password'}
        description={'Fill out the feilds to change your passowrd'}
        isOpen={isOpen}
        okText={'Change'}
        setIsOpen={setIsOpen}
        onSubmit={handleChangePassword}
        btnLoading={changingPasswordLoading}
      >
        <div className={styles.form_container}>
          <InputText
            label={'Current Password'}
            size="medium"
            type={'password'}
            value={passwordFormData?.current_password}
            onChange={(e) => setPasswordFormFeildData('current_password', e?.target?.value)}
            error={clientError?.current_password ? clientError?.current_password : false}
          />
          <br />
          <InputText
            label={'New Password'}
            size="medium"
            type={'password'}
            value={passwordFormData?.new_password}
            onChange={(e) => setPasswordFormFeildData('new_password', e?.target?.value)}
            error={clientError?.new_password ? clientError?.new_password : false}
          />
          <br />
          <InputText
            label={'Confirm Password'}
            size="medium"
            type={'password'}
            value={passwordFormData?.confirm_password}
            onChange={(e) => setPasswordFormFeildData('confirm_password', e?.target?.value)}
            error={clientError?.confirm_password ? clientError?.confirm_password : false}
          />
        </div>
      </Modal>
      <div className={styles.home_container}>
        <Row gutter={[25, 20]}>
          <Col xs={24} sm={24} md={12}>
            <InputText
              size={'medium'}
              label={'Name'}
              value={formData?.name}
              onChange={(e) => setFormFeildData('name', e?.target?.value)}
              error={clientError?.name ? clientError?.name : false}
            />
          </Col>
          <Col xs={24} sm={24} md={12}>
            <InputText size={'medium'} label={'E-mail'} disabled={true} value={formData?.email} />
          </Col>
          <Col xs={24} sm={24} md={12}>
            <InputCountry
              size={'medium'}
              label={'Country'}
              value={formData?.country}
              onChange={(e) => setFormFeildData('country', e?.label)}
              error={clientError?.country ? clientError?.country : false}
            />
          </Col>
          <Col xs={24} sm={24} md={12}>
            <InputText
              size={'medium'}
              label={'Company name'}
              value={formData?.company}
              onChange={(e) => setFormFeildData('company', e?.target?.value)}
            />
          </Col>
          <Col xs={24} sm={24} md={24}>
            <InputText
              type={'number'}
              size={'medium'}
              label={'Company size'}
              value={formData?.company_size}
              onChange={(e) => setFormFeildData('company_size', e?.target?.value)}
            />
          </Col>
        </Row>
        <div className={styles.button_container}>
          <Button
            className="ant-btn-middle"
            loading={updateProfileLoading}
            disabled={updateProfileLoading}
            onClick={handleProfileSave}
          >
            Update
          </Button>
        </div>
      </div>
    </AuthLayout>
  );
};

export default Home;
