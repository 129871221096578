import React from 'react';
import styles from './UnAuthLayout.module.scss';
import { Image } from 'antd';

const UnAuthLayout = ({ children }) => {
  return (
    <div className={styles.unauth_layout_container}>
      <div className={styles.side_banner_container}>
        <Image src={'/Assets/auth/troni.png'} alt={'Troni logo'} className={styles.logo} preview={false} />
        <div className={styles.shape_1}>
          <Image src={'/Assets/auth/shape_1.png'} alt={'Shape'} preview={false} />
        </div>
        <div className={styles.shape_2}>
          <Image src={'/Assets/auth/shape_2.png'} alt={'Shape'} preview={false} />
        </div>
      </div>
      <div className={styles.side_content_container}>{children}</div>
    </div>
  );
};

export default UnAuthLayout;
