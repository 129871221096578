import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import { notification } from 'antd';

const initialState = {
  isLoading: false,
  isError: null,
  errors: [],
  isSuccess: false,
  message: '',
};

export const changePassword = createAsyncThunk('user/change-password', async (data, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/user/change-password`, data);
    response?.data?.message && notification.success({ message: 'Success', description: response?.data?.message });
    return response?.data;
  } catch (error) {
    notification.error({
      message: 'Error',
      description: error?.response?.data?.errors[0]?.message || 'Network error',
    });
    return rejectWithValue(error?.response?.data);
  }
});

const changePasswordSlice = createSlice({
  name: 'Change Password',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(changePassword.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(changePassword.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.errors = [];
      state.isSuccess = action.payload?.success;
      state.message = action.payload?.message;
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.isLoading = false;
      state.errors = action.payload?.errors || [];
      state.isError = true;
    });
  },
});

export default changePasswordSlice?.reducer;
