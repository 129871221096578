import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
import Spinner from '../../Components/Commons/Spinner/Spinner';
import { getUser } from '../../Redux/Features/userSlice';
import { setCookie } from '../../utils/helper';
import styles from './GoogleAuth.module.scss';
import { notification } from 'antd';

function useQuery(key) {
  const { search } = useLocation();
  return new URLSearchParams(search).get(key);
}

const GoogleAuth = () => {
  const failed = useQuery('failed');
  const token = useQuery('token');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAuthSuccess = () => {
    setCookie('token', token);
    notification?.success({ message: 'Success', description: 'Authentication Completed' });
    dispatch(getUser(token));
    navigate(`/`);
  };

  useEffect(() => {
    if (token) {
      handleAuthSuccess();
    } else if (failed) {
      notification?.error({ message: 'Error', description: failed });
    }
  }, []);

  return <div className={styles.google_container}>{failed ? <Navigate to={'/login'} /> : <Spinner size={100} />}</div>;
};

export default GoogleAuth;
