export const theme = {
  token: { colorPrimary: '#2563EB', fontFamily: "'Plus Jakarta Sans', sans-serif" },
  components: {
    Button: {
      defaultColor: 'var(--dark-gray-dark-gray-2, #252c32)',
      defaultBorderColor: 'var(--light-gray-light-gray-2, #dde2e4)',
      fontWeight: 600,
      // Button SM
      contentFontSizeSM: 13,
      paddingInlineSM: 41,
      paddingBlockSM: 10,
      // controlHeightSM: 40,
      borderRadiusSM: 10,
      // Button MD
      paddingInline: 41,
      paddingBlock: 12,
      // controlHeight: 48,
      fontSize: 13,
      borderRadius: 10,
      // Button LG
      contentFontSizeLG: 13,
      paddingInlineLG: 41,
      paddingBlockLG: 10.5,
      // controlHeightLG: 55,
      borderRadiusLG: 6,
    },
  },
};
