import React from 'react';
import styles from './Navbar.module.scss';
import { Avatar, Image } from 'antd';

const Navbar = () => {
  return (
    <div className={styles.navbar_container}>
      <div className={styles.nav_item}>
        <h3>TRONI</h3>
        <Avatar
          size={'large'}
          icon={<Image src={'/Assets/placeholder.png'} alt={'user default image'} preview={false} />}
        />
      </div>
    </div>
  );
};

export default Navbar;
