import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import validator from 'validator';
import InputText from '../../Components/Commons/InputText/InputText';
import { Button } from 'antd';
import { setNewPassword } from '../../Redux/Features/setNewPasswordSlice';
import styles from './SetNewPassword.module.scss';

const SetNewPassword = () => {
  const { code } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state?.setNewPassword);
  const [clientError, setClientError] = useState({});
  const [formData, setFormData] = useState({
    password: '',
  });

  const setFeild = (feildName, feildValue) => {
    setFormData({ ...formData, [feildName]: feildValue });
  };

  const handleSubmit = async () => {
    let isErr = false;
    let errors = {};

    if (!validator.isLength(formData?.password, { min: 6 })) {
      isErr = true;
      errors.password = 'Password should be 6 or more characters long';
    }

    if (validator.isEmpty(formData?.password)) {
      isErr = true;
      errors.password = 'Password is required';
    }

    if (isErr) {
      isErr = false;
      setClientError(errors);
    } else {
      setClientError({});
      const data = {
        data: {
          ...formData,
          code,
        },
        navigate,
        onSuccess: () => {
          setFormData({
            password: '',
          });
        },
      };
      dispatch(setNewPassword(data));
    }
  };

  return (
    <div className={styles.main_forgot_passowrd_container}>
      <div className={styles.forgot_password_container}>
        <div className={styles.title_container}>
          <h2>Set New Password</h2>
        </div>
        <div className={styles.form_container}>
          <div className={styles.input_container}>
            <InputText
              label={'New Password *'}
              type={'password'}
              error={clientError?.password ? clientError?.password : false}
              value={formData?.password}
              size={'large'}
              onChange={(e) => setFeild('password', e?.target?.value)}
            />
          </div>
          <div className={styles.button_container}>
            <Button onClick={handleSubmit} disabled={isLoading} loading={isLoading}>
              Set password
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetNewPassword;
