import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { getCookie } from '../utils/helper';
import { useSelector } from 'react-redux';

const PublicRoute = ({ children, element }) => {
  const { isSuccess, isError } = useSelector((state) => state?.user);
  let [isToken, setIsToken] = useState(getCookie('token'));
  let [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let data = getCookie('token');
    setIsLoading(false);
    setIsToken(data);
  }, []);

  if (isToken && isSuccess) return children || element;
  if (!isLoading && isError) return <Navigate to={'/login'} />;
};

export default PublicRoute;
