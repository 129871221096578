import React, { useEffect } from 'react';
import styles from './AllCampaigns.module.scss';
import AuthLayout from '../../../Layouts/AuthLayout/AuthLayout';
import SettingsHeader from '../../../Components/SettingsHeader/SettingsHeader';
import { Button, Image } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCampaigns } from '../../../Redux/Features/getCampaignsSlice';
import { getQueryParameters } from '../../../utils/helper';

const AllCampaigns = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { products } = useSelector((state) => state?.getProducts);
  const { campaigns } = useSelector((state) => state?.getCampaigns);

  useEffect(() => {
    const parameters = getQueryParameters(location?.search);
    if (parameters?.success) {
      dispatch(getCampaigns());
    }
  }, [location]);

  const getProductName = (product_id) => {
    const product = products?.find((item) => item?._id === product_id);
    return product?.product_name;
  };

  const styleHandler = (campaign) => {
    if (campaign.is_active && campaign?.status === 'Active') {
      return styles.active;
    }
    if (!campaign.is_active && campaign?.status === 'Blocked') {
      return styles.canceled;
    }
    if (campaign?.status === 'Login Failed') {
      return styles.canceled;
    }
    if (campaign?.status === 'Invalid Business Page') {
      return styles.canceled;
    }
    if (!campaign.is_active) {
      return styles.paused;
    }
  };

  return (
    <AuthLayout>
      <SettingsHeader
        content={
          <div className={styles.profile_setting_container}>
            <div className={styles.profile_details}>
              <h2>Campaigns</h2>
            </div>
            <Button size="small" onClick={() => navigate('/create-campaign')}>
              Create new campaign <Image src={'/Assets/plus_icon.svg'} alt={'Plus icon'} preview={false} />
            </Button>
          </div>
        }
      />
      <div className={styles.main_campaigns_container}>
        <div className={styles.title_container}>
          <h6>Campaigns</h6>
        </div>
        {campaigns?.map((item, i) => (
          <div
            className={styles.campaign}
            key={i}
            onClick={() =>
              navigate(
                item?.status === 'Un-Paid' ? `/create-campaign?id=${item?._id}` : `/campaign/${item?._id}`
              )
            }
          >
            <div className={styles.title}>
              <h5>{item?.title}</h5>
            </div>
            <div className={styles.status}>
              <div className={styleHandler(item)}>
                <div className={styles.dot}></div>
                <span>{item?.status}</span>
              </div>
            </div>
            <div className={styles.card_info}>
              {item?.created_by === 'INTERNAL' ? (
                <>
                  <p>External</p>
                  <span>External Payment</span>
                </>
              ) : (
                <>
                  <p></p>
                  <span></span>
                </>
              )}
            </div>
            <div className={styles.subscription}>
              <p>
                {getProductName(item.product_id)}
              </p>
            </div>
          </div>
        ))}
      </div>
    </AuthLayout>
  );
};

export default AllCampaigns;
