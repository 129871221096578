import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import { notification } from 'antd';

const initialState = {
  isLoading: false,
  isError: null,
  errors: [],
  isSuccess: false,
  message: '',
  details: [],
};

export const getCompanies = createAsyncThunk(
  'campaign/get-companies',
  async ({ id, data }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(`${BASE_URL}/api/campaign/organization-details/${id}`, {
        search: data,
        type: ['company', 'organizationalPage'],
      });
      return response?.data;
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error?.response?.data?.errors[0]?.message || 'Network error',
      });
      return rejectWithValue(error?.response?.data);
    }
  }
);

const getCompaniesSlice = createSlice({
  name: 'Get Companies',
  initialState: initialState,
  reducers: {
    resetCompanyDetails: (state) => {
      state.details = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCompanies.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(getCompanies.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.errors = [];
      state.isSuccess = action?.payload?.success;
      state.message = action?.payload?.message;
      state.details = action?.payload?.details;
    });
    builder.addCase(getCompanies.rejected, (state, action) => {
      state.isLoading = false;
      state.errors = action.payload?.errors || [];
      state.details = [];
      state.isError = true;
    });
  },
});

export const { resetCompanyDetails } = getCompaniesSlice.actions;

export default getCompaniesSlice?.reducer;
