import moment from 'moment';

export const setCookie = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const getCookie = (key) => {
  return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : '';
};

export const removeCookie = (key) => {
  return localStorage.removeItem(key);
};

export const formatDate = (date, format) => {
  return moment(date).format(format);
};

export const formatDateToStandard = (date) => {
  const momentInstance = moment(date, false);
  return momentInstance.isValid() ? momentInstance.format('YYYY-MM-DD') : 'N/A';
}

export const extractNumberFromString = (text) => text && text?.replace(/\D/g, '');

export const getTime = (time) => time && moment(time).format('h:mm A');

export const getFormattedNumber = (number) => {
  if (!number) return;
  let phoneNum;
  const cleaned = number?.replace(/[^\d]/g, '');
  if (cleaned?.length === 10) {
    phoneNum = `${cleaned}`;
    phoneNum = phoneNum?.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3');
    let finalPhone = '(' + phoneNum?.slice(0, 3) + ')' + phoneNum?.slice(3, 7) + ' -' + phoneNum?.slice(7);
    return finalPhone;
  } else {
    return number;
  }
};

export const getQueryParameters = (url) => {
  const queryParams = {};

  const queryString = url.split('?')[1];

  if (queryString) {
    const paramPairs = queryString.split('&');

    paramPairs.forEach((pair) => {
      const [key, value] = pair.split('=');
      queryParams[key] = decodeURIComponent(value);
    });
  }

  return queryParams;
};

export const encodeText = (text) => {
  try {
    return encodeURIComponent(text);
  } catch (error) {
    // If encoding fails, return the original string
    console.error('Error encoding text:', error);
    return text;
  }
};

export const removeDuplicates = (array, property) => {
  const seenValues = [];

  return array
    ?.map((n) => {
      let value = property ? n[property] : n;
      if (!seenValues?.includes(value)) {
        seenValues?.push(value);
        return n;
      }
    })
    ?.filter((n) => n && n);
};

export const getAllProductsToDisplay = (products) => {
  let copyProducts = [...products];
  const filteredProducts =
    copyProducts
      ?.filter((product) => product.is_active)
      .map((product) => {
        return { value: product?._id, label: product?.product_name };
      });
  return filteredProducts?.length > 0 ? filteredProducts : [];
}
