import { Image } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './EmailSended.module.scss';

const EmailSended = () => {
  return (
    <div className={styles.email_sended_container}>
      <div className={styles.details_container}>
        <div className={styles.image_container}>
          <Image src={'/Assets/email-sended.png'} alt={'Email sended logo'} preview={false} />
        </div>
        <h3>Email Verification</h3>
        <p>Email for verificaiton has been sent</p>
        <Link to={`/login`}>Back to login</Link>
      </div>
    </div>
  );
};

export default EmailSended;
