import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import PageLoad from '../../Components/Commons/PageLoad/PageLoad';
import Navbar from '../../Components/Navbar/Navbar';
import styles from './AuthLayout.module.scss';
import ScreenLoad from '../../Components/Commons/ScreenLoad/ScreenLoad';

const AuthLayout = ({ children, loading, loadingInfo }) => {
  return (
    <Fragment>
      {loading && <ScreenLoad info={loadingInfo} />}
      <div className={styles.auth_layout_container}>
        <Navbar />
        <div className={styles.main_container}>{children}</div>
      </div>
    </Fragment>
  );
};

export default AuthLayout;
