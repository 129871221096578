import React, { useEffect, useState } from 'react';
import { Modal as AntModal } from 'antd';
import './modal.scss';
import Spinner from '../Spinner/Spinner';

const Modal = ({
  isOpen,
  setIsOpen,
  onSubmit,
  children,
  element,
  centered = true,
  title,
  description,
  cancelText,
  okText,
  btnLoading = false,
  loadingPrompt,
  onCancel,
  okBtnHidden,
  cancelBtnHidden,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const handleOk = () => {
    onSubmit && onSubmit();
  };
  const handleCancel = () => {
    setIsOpen && setIsOpen(false);
    onCancel && onCancel();
  };

  useEffect(() => {
    if (!isOpen) return;
    setIsModalOpen(isOpen);
  }, [isOpen]);

  if (!isOpen) return;

  return (
    <AntModal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={() => handleCancel()}
      centered={centered}
      okText={okText}
      cancelText={cancelText}
      cancelButtonProps={{
        onClick: () => handleCancel(),
        size: 'small',
        style: cancelBtnHidden && { display: 'none' },
      }}
      okButtonProps={{
        disabled: btnLoading,
        loading: btnLoading,
        onClick: () => handleOk(),
        size: 'small',
        style: okBtnHidden && { display: 'none' },
      }}
    >
      <div className={`modal_container ${btnLoading ? 'loading_state' : ''}`}>
        <div className={`modal_header_container`}>
          {btnLoading && loadingPrompt ? (
            <h4 className="loading_check_state_red">
              <Spinner type="danger" /> {loadingPrompt}
            </h4>
          ) : (
            <>
              <h4>{title}</h4>
              <span>{description}</span>
            </>
          )}
        </div>
        <div className={`modal_body_container`}>{children || element || 'Empty Modal'}</div>
      </div>
    </AntModal>
  );
};

export default Modal;
