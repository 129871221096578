import React from 'react';
import styles from './SettingsHeader.module.scss';
import TabsBar from '../TabsBar/TabsBar';

const SettingsHeader = ({ content, tabsSideContent, tabList }) => {
  return (
    <div className={styles.setting_header_container}>
      <div className={styles.tabs_container}>
        <TabsBar content={tabsSideContent} list={tabList} />
      </div>
      <div className={styles.setting_main_content_container}>{content}</div>
    </div>
  );
};

export default SettingsHeader;
